import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import Greentick from "@assets/images/checked.png";
import QRCode from "qrcode";

const GenerateTransitPass = (props) => {
  const { pass_item } = props;
  const [qrCodeData, setQrCodeData] = useState("");

  const valid_till = moment(Number(pass_item?.valid_till)).format("DD/MM/YYYY");

  useEffect(() => {
    if (pass_item?.id) {
      // Generate the QR code as a base64 image
      // const url = encodeURI(
      //   // `${window.location.origin}/generate-transit-pass-view/${pass_item.id}`
      //   `https://pvm-sit.vertace.org/generate-transit-pass-view/${pass_item.id}`
      // );
      const url = `https://pvm-sit.vertace.org/generate-transit-pass-view/${pass_item.id}`;

      console.log("url", url);

      QRCode.toDataURL(url, { width: 100 }, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }
        setQrCodeData(url);
      });
    }
  }, [pass_item?.id]);

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        {/* V.P. No. Section */}
        <View style={styles.headerSection}>
          <Text style={styles.vpNo}>V.P. No. {pass_item?.pass_number}</Text>
          <View style={styles.qr_code}>
            {qrCodeData && <Image src={qrCodeData} style={styles.qrImage} />}
          </View>
        </View>

        {/* Title Section */}
        <View style={styles.titleSection}>
          <Text style={styles.title}>SECRETARIAT CAR PASS</Text>
          <Text style={styles.subTitle}>TRANSIT ONLY</Text>
        </View>

        {/* Main Content */}
        <View style={styles.bodySection}>
          <Text style={styles.contentText}>
            Permit:{" "}
            <Text style={styles.text_details}>
              {pass_item?.pass_type?.parent_pass_type?.name}
            </Text>{" "}
            Vehicle No:
            <Text style={styles.text_details}>{pass_item?.vehicle_number}</Text>
          </Text>
          <Text style={styles.contentText}>{" "}
            of Thiru/Tmt/M/s:{" "}
            <Text style={styles.text_details}>{pass_item?.name}</Text> for
            Transit entry into the
          </Text>
          <Text style={styles.contentText}>
            Secretariat Campus. Parking of the vehicle will be
          </Text>
          <Text style={styles.contentText}>
            only at "Parking Area" in Marina Garden
          </Text>
          <Text style={styles.contentText}>
            (opposite to Secretariat). This Transit entry pass is valid up to{" "}
            <Text style={styles.text_details}>{valid_till}</Text> .
          </Text>
        </View>

        {/* Footer Section */}
        <View style={styles.footerSection}>
          <Text style={styles.footerText}>Under Secretary to Government,</Text>
          <Text style={styles.footerText}>Public(MV) Department,</Text>
          <Text style={styles.footerText}>Secretariat, Chennai-9</Text>
          <View>
            <Image style={styles.image2} src={Greentick} />
          </View>
          <Text style={styles.footerText}>
            Parking inside Secretariat campus is strictly prohibited
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: "Times-Roman",
    flexDirection: "column",
    justifyContent: "space-between",
    border: 1,
    borderColor: "#000000",
    borderWidth: "5px",
  },
  headerSection: {
    textAlign: "left",
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  vpNo: {
    fontSize: 26,
    fontWeight: "extrabold",
  },
  qr_code: {
    textAlign: "right",
  },
  qrImage: {
    width: 70,
    height: 70,
  },
  titleSection: {
    textAlign: "center",
    marginBottom: 20,
    lineHeight: 1.5,
  },
  title: {
    fontSize: 34,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  subTitle: {
    fontSize: 36,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  bodySection: {
    marginBottom: 30,
    lineHeight: 1.5,
    width: "100%",
  },
  contentText: {
    fontSize: "24px",
    textAlign: "justify",
    marginBottom: 10,
  },
  text_details: {
    fontSize: "24px",
    textAlign: "justify",
    textDecoration: "underline",
  },
  footerSection: {
    textAlign: "center",
    marginTop: 20,
  },
  footerText: {
    fontSize: 18,
    marginBottom: 5,
  },
  image2: {
    alignItems: "center",
    width: "10%",
    zIndex: "-1",
    opacity: "0.5",
    position: "absolute",
    height: "66px",
    left: "245px",
    bottom: "8px",
  },
});

export default GenerateTransitPass;
